var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('BtnCircle',{attrs:{"tLabel":"Atrás","tPosition":"bottom","icon":"mdi-arrow-left","color":"grey darken-3","size":"x-small","to":{
            name: 'contratantes',
          }}}),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(
            ((_vm.$route.meta.title) + " " + (_vm.data && _vm.data.length == 0 ? '' : (" (" + (_vm.data.length) + ")")))
          )}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})]),(_vm.login.role_id == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"teal","loading":_vm.btn_xls_ldg,"disabled":_vm.data.length == 0},on:{"click":function($event){$event.preventDefault();return _vm.downloadXls.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v(" mdi-table-arrow-down ")])],1)]}}],null,false,2107618042)},[_c('span',{domProps:{"textContent":_vm._s('Descargar tabla XLS')}})]):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[(_vm.data.length > 0)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.user_id",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.user_id ? 'success' : ''},domProps:{"textContent":_vm._s(item.user_id ? 'mdi-check' : 'mdi-close')}})]}},{key:"item.index",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.medical_treatment",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":(item.medical_treatment ? 'pink ' : 'grey') + ' darken-1'}},on),[_vm._v(" mdi-shield-account ")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(
                  (item.medical_treatment ? 'Con' : 'Sin') +
                  ' tratamiento médico'
                )}})])]}},{key:"item.revision_pending.revision_user",fn:function(ref){
                var item = ref.item;
return [(item.revision_pending)?_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"small":"","color":item.revision_pending.revision == null
                    ? 'warning'
                    : item.revision_pending.revision &&
                      item.revision_pending.accepted
                    ? 'success'
                    : 'error'}},[_vm._v(" mdi-"+_vm._s(item.revision_pending.revision == null ? "alert" : item.revision_pending.revision && item.revision_pending.accepted ? "check" : "close")+" ")]),(item.revision_pending.revision)?_c('div',[_c('span',{domProps:{"textContent":_vm._s(item.revision_pending.revision_user)}}),_c('br'),_c('small',{domProps:{"textContent":_vm._s(item.revision_pending.revision_date)}})]):_vm._e()],1):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alert ")])]}},{key:"item.revision_pending.confirmation_user",fn:function(ref){
                    var item = ref.item;
return [(item.revision_pending)?_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"small":"","color":item.revision_pending.confirmation == null
                    ? 'warning'
                    : item.revision_pending.confirmation &&
                      item.revision_pending.accepted
                    ? 'success'
                    : 'error'}},[_vm._v(" mdi-"+_vm._s(item.revision_pending.confirmation == null ? "alert" : item.revision_pending.confirmation && item.revision_pending.accepted ? "check" : "close")+" ")]),(item.revision_pending.confirmation)?_c('div',[_c('span',{domProps:{"textContent":_vm._s(item.revision_pending.confirmation_user)}}),_c('br'),_c('small',{domProps:{"textContent":_vm._s(item.revision_pending.confirmation_date)}})]):_vm._e()],1):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alert ")])]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"primary","to":{
                    name: "asegurados.view",
                    params: {
                      id: item.id,
                      contractor_id: item.contractor_id,
                    },
                  }}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-eye-outline')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Ver')}})])]}}])})],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }